import React from 'react';
import { graphql } from 'gatsby';
import Layout3 from '../components/layout2';
import Styles from "./mdlist.module.css";









const HomePage = (props: any) => {
  const { data } = props;


  return (
    <Layout3>
        <div style={{margin: "30px"}}>
        <h1>更新情報</h1>
        <p>サイト構築へのご協力ありがとうございます。</p>


<h2>第16稿  - 12/25</h2>

<h3>
  kmlデータとの接続（２都市比較）
</h3>
<p>
　・２都市比較のページでも、データとの接続を行いました。kmlもダウンロードできるようになりました。
</p>
        
<h2>第15稿  - 12/24</h2>
        
<h3>
  kmlデータとの接続
</h3>
<p>
　・データとの接続を行いました。kmlもダウンロードできるようになりました。
<br />
　・これにて、Ver3とVer6の参照は不要になります。（これまでお手数をおかけしました。）
</p>

<h3>
  凡例まわりのデザイン修正
</h3>
<p>
　・画像とテキストを組み合わせたデザインに修正しました。
<br />
　　<a href="/105"><b>こちらからご確認ください</b></a>
<br />
</p>

<h3>
    「登録情報一覧」ページの更新
</h3>
<p>
　・サイト内の情報を抽出し、見やすい表形式で整理しています。
<br />
　・データ更新方法についての解説をページ内に掲載しました。どなたでも修正や校正に関わっていただけます。
<br />
　　<a href="/mdlist"><b>「登録情報一覧」ページはこちらから</b></a>
</p>



<img src="https://source.unsplash.com/featured/?mt.fuji" alt="" /></div>

    </Layout3>
  );
};

export default HomePage;

